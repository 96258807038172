<template>
  <b-button
    :block="block"
    :disabled="disabled"
    :variant="variant"
  >
    <b-spinner small />
    Loading...
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },
  props: {
    block: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
