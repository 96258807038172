/* eslint-disable no-restricted-syntax */
import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes

import { isUserLoggedIn, getUserInfo } from '@/auth/utils'
import analytics from './routes/analytics'
import applicantTrackingSystem from './routes/applicant-tracking-system'
import auditLogs from './routes/audit-logs'
import auth from './routes/auth'
import dataManager from './routes/data-manager'
import docs from './routes/docs'
import eventManagementSystem from './routes/event-management-system'
import misc from './routes/misc'
import pages from './routes/pages'
import profile from './routes/profile'
import userManager from './routes/user-manager'
import companies from './routes/companies'
import roles from './routes/roles'
import helpDesks from './routes/help-desks'
// import marketplace from './routes/marketplace'
import email from './routes/email'
import { AccountTypes, UserGroups } from '../constants/enums'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', name: 'home' },
    ...analytics,
    ...applicantTrackingSystem,
    ...auditLogs,
    ...auth,
    ...dataManager,
    ...eventManagementSystem,
    ...docs,
    ...misc,
    ...pages,
    ...profile,
    ...userManager,
    ...companies,
    ...roles,
    ...helpDesks,
    // ...marketplace,
    ...email,
    {
      path: '*',
      redirect: { name: 'page-404' },
    },
  ],
})

router.beforeEach((to, from, next) => {

  // Check if `from` has content, store it as the previous route
  if (from && from.path) {
    localStorage.setItem('previousRoute', JSON.stringify({
      path: from.path,
      query: from.query,
    }));
  }

  Vue.nextTick(() => {
    router.app.$store.dispatch('auth/getLoggedInUser', { router_app: router.app, publicPage: to.meta.allowAnonymous || null })
  })
  const trackingInfo = {}

  for (const [key, value] of Object.entries(to.query)) {
    if (key.startsWith('utm')) {
      trackingInfo[key] = value
    }
  }
  
  if (to.name !== 'page-event') {
    localStorage.removeItem('track_job_id')
    if (from.name && !from.name.includes('listings-page')) {
      localStorage.removeItem('track_event_id')
    }
  }

  if (from.name && from.name.includes('listings-page') && to.name !== 'page-event') {
    localStorage.removeItem('track_event_id')
  }

  // * Access the previous route using "from" argument
  // if (
  //   [
  //     'listings-page', 'company-listings-page',
  //     'kiosk-page', 'kiosk-company-page',
  //   ].includes(from.name)
  // ) {
  //   localStorage.setItem('listings_filter', JSON.stringify(from.query))
  // }

  const isLoggedIn = isUserLoggedIn()
  // const utmTrackingEnabled = to.fullPath.toLowerCase().includes('utm')

  // const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  // const isFirefox = /Firefox/.test(navigator.userAgent)
  // const browserAllowed = isChrome || isFirefox

  // if the page does not require authorization, allow access
  // if (!browserAllowed) {
  //   if (to.path !== '/unsupported-browser') {
  //     return next('/unsupported-browser')
  //   }
  //   return next()
  // }
  const userInfo = getUserInfo()
  
  if (to.meta.allowAnonymous && !to.meta.auth) {
    return next()
  }
  if (to.name === 'app-login') {
    return next()
  }
  // if user has logged in proceed with the requested page
  if (isLoggedIn && to.meta.auth) {
    return next({ path: '/', query: { redirect: to.fullPath } })
  }
  // if user not authorised send to authentication page
  if (!isLoggedIn && !to.meta.auth) {
    if (!to.meta.allowAnonymous) {
      return next({ path: '/kiosk', query: { redirect: to.fullPath } })
    }
  }
  if (to.meta.section) {
    const allAllowedSections = userInfo.permissions.map(permission => {
      if (permission.read) {
        return permission.section
      }
    })
    if (!allAllowedSections.includes(to.meta.section)) {
      return next({ path: '/unauthorized', query: { redirect: to.fullPath } })
    }
  }
  // if (to.path === '/') {
  //   if (userInfo) {
  //     if (userInfo && isLoggedIn && !userInfo.account_approved && !userInfo.user_group.includes(UserGroups.SUPERUSER)) {
  //       return next({ path: '/await-approval' })
  //     }

  //     if (userInfo.account_type === AccountTypes.EMPLOYER || userInfo.account_type === AccountTypes.AGENCY) {
  //       return next({ path: '/events-manager/events' })
  //     }
  //     if (userInfo.account_type === AccountTypes.APPLICANT) {
  //       return next({ path: '/events-list' })
  //     }
  //   }
  // } else {
  //   if (to.fullPath === '/await-approval') {
  //     return next()
  //   }
  //   if (userInfo && isLoggedIn && !userInfo.account_approved && !userInfo.user_group.includes(UserGroups.SUPERUSER)) {
  //     return next({ path: '/await-approval' })
  //   }
  // }

  // * TRACKING
  // if (!isLoggedIn) {
  //   if (Vue.prototype.$isEmpty(localStorage.getItem('tracking_id'))) {
  //     localStorage.setItem('tracking_id', Vue.prototype.$generateUUID4())
  //   }
  // }

  const utmSearchList = ['utm_source', 'utm_medium', 'utm_campaign']
  const decodedUrl = decodeURIComponent(window.location.href).toString()

  if (utmSearchList.some(substr => decodedUrl.includes(substr))) {
    const urlParams = new URLSearchParams(window.location.search);

    const utmSource = urlParams.get('utm_source')
    const utmMedium = urlParams.get('utm_medium')
    const utmCampaign = urlParams.get('utm_campaign')

    if (utmSource) {
      localStorage.setItem('utm_source', utmSource)
    }
    if (utmMedium) {
      localStorage.setItem('utm_medium', utmMedium)
    }
    if (utmCampaign) {
      localStorage.setItem('utm_campaign', utmCampaign)
    }
  }

  if (isLoggedIn) {
    if (
      Vue.prototype.$isNotEmpty(localStorage.getItem('utm_source'))
      || Vue.prototype.$isNotEmpty(localStorage.getItem('utm_source'))
      || Vue.prototype.$isNotEmpty(localStorage.getItem('utm_source'))
    ) {
      Vue.prototype.$http
        .put(`/api/user-tracking-details`, {
          form_data: {
            user_id: userInfo.id,
            utm_source: localStorage.getItem('utm_source'),
            utm_medium: localStorage.getItem('utm_medium'),
            utm_campaign: localStorage.getItem('utm_campaign'),
            timestamp: new Date(),
          },
        })
        .then(response => {
          const { output, success, message } = response.data
  
          if (success) {
            localStorage.removeItem('utm_source')
            localStorage.removeItem('utm_medium')
            localStorage.removeItem('utm_campaign')
          }
        })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading

  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  // const trackingInfo = {}

  // for (const [key, value] of Object.entries(to.query)) {
  //   if (key.startsWith('utm')) {
  //     trackingInfo[key] = value
  //   }
  // }
  const isLoggedIn = isUserLoggedIn()
  // const utmTrackingEnabled = to.fullPath.toLowerCase().includes('utm')

  const userInfo = getUserInfo()
  // router.app.$store.dispatch('auth/updateSession', {
  //   router_app: router.app,
  //   user_id: userInfo ? userInfo.id : null,
  //   tracking_info: trackingInfo,
  //   session_type: isLoggedIn ? 'Authenticated' : 'Anonymous',
  // })
  // if (utmTrackingEnabled) {
  //   if (isLoggedIn) {
  //     router.app.$store.dispatch('auth/updateSession', {
  //       router_app: router.app,
  //       user_id: userInfo ? userInfo.id : null,
  //       tracking_info: trackingInfo,
  //       session_type: userInfo ? 'Authenticated': 'Anonymous',
  //     })
  //   } else {
  //     router.app.$store.dispatch('auth/updateSession', {
  //       router_app: router.app,
  //       user_id: userInfo ? userInfo.id : null,
  //       tracking_info: trackingInfo,
  //       session_type: 'Anonymous',
  //     })
  //   }
  // }
})

export default router
